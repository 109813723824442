import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto jwe`}</strong>{` -- encrypt and decrypt data and keys using JSON Web Encryption (JWE)`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto jwe <subcommand> [arguments] [global-flags] [subcommand-flags]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p>{`The `}<strong parentName="p">{`step crypto jwe`}</strong>{` command group provides facilities for encrypting and
decrypting content and representing encrypted content using JSON-based data
structures as defined by the JSON Web Encryption (JWE) specification in
RFC7516, using algorithms defined in the JSON Web Algorithms (JWA)
specification in RFC7518. A JWE is a data structure representing an encrypted
and integrity-protected message.`}</p>
    <p>{`There are two JWE serializations: the compact serialization is a small, URL-
safe representation that base64 encodes the JWE components. The compact
serialization is a URL-safe string, suitable for space-constrained
environments such as HTTP headers and URI query parameters. The JSON
serialization represents JWEs as JSON objects and allows the same content to
be encrypted to multiple parties (using multiple keys).`}</p>
    <p>{`A typical JWE in compact serialization is a dot-separated string with five
parts:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Header: metadata describing how the plaintext payload was processed to
produce ciphertext (e.g., which algorithms were used to encrypt the
content encryption key and the plaintext payload)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Encrypted Key: the "content encryption key" that was used to encrypt the
plaintext payload, encrypted for the JWE recipient(s) (see: "what's with
the encrypted key" below)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Initialization Vector: an initialization vector for use with the specified
encryption algorithm, if applicable`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Ciphertext: the ciphertext value resulting produced from authenticated
encryption of the plaintext with additional authenticated data`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Authentication Tag: value resulting from the authenticated encryption of
the plaintext with additional authenticated data`}</p>
      </li>
    </ul>
    <h2>{`What's with encrypted key?`}</h2>
    <p>{`This is somewhat confusing. Instead of directly encrypting the plaintext
payload, JWE typically generates a new "content encryption key" then encrypts
`}<em parentName="p">{`that key`}</em>{` for the intended recipient(s).`}</p>
    <p>{`While versatile, JWE is easy to use incorrectly. Therefore, any use of this
subcommand requires the use of the '--subtle' flag as a misuse prevention
mechanism. You should only use this subcommand if you know what you're doing.
If possible, you're better off using the higher level 'crypto nacl' command
group.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`This example demonstrates how to produce a JWE for a recipient using the
RSA-OAEP algorithm to encrypt the content encryption key (producing the
encrypted key), and the A256GCM (AES GCM with 256-bit key) algorithm to
produce the ciphertext and authentication tag.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Encode the JWE header with the desired "alg" and "enc" members then
encode it producing the `}<em parentName="p">{`header`}</em></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-raw"
          }}>{`BASE64URL(UTF8({"alg":"RSA-OAEP","enc":"A256GCM"}))
=> eyJhbGciOiJSU0EtT0FFUCIsImVuYyI6IkEyNTZHQ00ifQ
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Generate a random content encryption key (CEK), encrypt it using
RSA-OAEP, producing the `}<em parentName="p">{`encrypted key`}</em></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Generate a random initialization vector`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Perform authenticated encryption over the plaintext using the content
encryption key and A256GCM algorithm with the base64-encoded JWE headers
provided as additional authenticated data producing the `}<em parentName="p">{`ciphertext`}</em>{` and
`}<em parentName="p">{`authentication tag`}</em></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Assemble the final result (compact serialization) to produce the string:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-raw"
          }}>{`BASE64URL(UTF8(header)) || '.'
|| BASE64URL(encrypted key) || '.'
|| BASE64URL(initialization vector) || '.'
|| BASE64URL(ciphertext) || '.'
|| BASE64URL(authentication tag)
`}</code></pre>
        <p parentName="li">{`Producing a result like (line breaks for display purposes only):`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-raw"
          }}>{`eyJhbGciOiJSU0EtT0FFUCIsImVuYyI6IkEyNTZHQ00ifQ.
OKOawDo13gRp2ojaHV7LFpZcgV7T6DVZKTyKOMTYUmKoTCVJRgckCL9kiMT03JGe
ipsEdY3mx_etLbbWSrFr05kLzcSr4qKAq7YN7e9jwQRb23nfa6c9d-StnImGyFDb
Sv04uVuxIp5Zms1gNxKKK2Da14B8S4rzVRltdYwam_lDp5XnZAYpQdb76FdIKLaV
mqgfwX7XWRxv2322i-vDxRfqNzo_tETKzpVLzfiwQyeyPGLBIO56YJ7eObdv0je8
1860ppamavo35UgoRdbYaBcoh9QcfylQr66oc6vFWXRcZ_ZT2LawVCWTIy3brGPi
6UklfCpIMfIjf7iGdXKHzg.
48V1_ALb6US04U3b.
5eym8TW_c8SuK0ltJ3rpYIzOeDQz7TALvtu6UG9oMo4vpzs9tX_EFShS8iB7j6ji
SdiwkIr3ajwQzaBtQD_A.
XFBoMYUZodetZdvTiFvSkQ
`}</code></pre>
      </li>
    </ol>
    <p>{`Create a JWK for encryption use:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto jwk create --use enc p256.enc.pub p256.enc.priv
`}</code></pre>
    <p>{`Encrypt a message using the previous public key (output indented for display purposes):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ echo The message | step crypto jwe encrypt --key p256.enc.pub
{
  "protected":"eyJhbGciOiJFQ0RILUVTIiwiZW5jIjoiQTI1NkdDTSIsImVwayI6eyJrdHkiOiJFQyIsImNydiI6IlAtMjU2IiwieCI6Ii1hakZFVlZaSWNRa0RxbkhpZ0NOWU5fa29nZkhxZnRGX1N3c2ZQeXlSRUUiLCJ5IjoicGpjVnJJZHRHSVpka05HS1FETEpIdG5SLUxudUI2V3k4bHpuX3REdm9BUSJ9LCJraWQiOiJHd0tSTUdXY1pWNFE2dGZZblpjZm90N090N2hjQ0t2cUJPVWljX0JoZ0gwIn0",
  "iv":"-10PlAIteHLVABtt",
  "ciphertext":"_xnGoE7vPCrXRRlK",
  "tag":"wcvj4sXXMc9qII_ySYNYGA"
}
`}</code></pre>
    <p>{`Decrypt the previous message using the private key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto jwe decrypt --key p256.enc.priv < message.json
Please enter the password to decrypt p256.enc.priv: 
The message
`}</code></pre>
    <p>{`Encrypt a message using a shared password:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ echo The message | step crypto jwe encrypt --alg PBES2-HS256+A128KW
Please enter the password to encrypt the content encryption key: 
{
  "protected":"eyJhbGciOiJQQkVTMi1IUzI1NitBMTI4S1ciLCJlbmMiOiJBMjU2R0NNIiwicDJjIjoxMDAwMDAsInAycyI6ImpKMnJpejJGZnhoSXVOS3JSYUJqc2cifQ",
  "encrypted_key":"p4xazaWvaAYC7NbHoAQTC4DxCX-rEjs7wvRF-OvaVliYzhdRtEdgzA",
  "iv":"Jw4JCCr-lLrE0irT",
  "ciphertext":"jcb3wKopsHmClh7s",
  "tag":"7ttDDDfuqA45puDu7KbVkA"
}
`}</code></pre>
    <p>{`Decrypt a message protected with shared password:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto jwe decrypt < message.json
Please enter the password to decrypt the content encryption key: 
The message
`}</code></pre>
    <h2>{`Commands`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "encrypt/"
              }}>{`encrypt`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`encrypt a payload using JSON Web Encryption (JWE)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "decrypt/"
              }}>{`decrypt`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`verify a JWE and decrypt ciphertext`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      